import { t } from '@lingui/macro'
import { useStore } from '@tanstack/react-store'
import React, { useCallback } from 'react'

import { globalSearchStore } from 'state/useGlobalSearch'
import { SearchChipsInputBase } from './SearchChipsInputBase'

type SearchChipsInputProps = {
  placeholder?: string
  isLoading?: boolean
}

export const SearchChipsInput: React.FC<SearchChipsInputProps> = ({
  placeholder = t`Search by words in conversations...`,
  isLoading = false,
}) => {
  const value = useStore(globalSearchStore)
  const handleChange = useCallback((value: string[]) => {
    globalSearchStore.setState(() => value)
  }, [])

  return (
    <SearchChipsInputBase
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      isLoading={isLoading}
    />
  )
}
